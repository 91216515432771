.loadingModal {
    background-color: rgb(255 255 255 / 50%);
}
.loadingModal .modal-content {
    background-color: transparent;
    box-shadow: none;
}
.loadingModal .modal-body {
    text-align: center;
}
.grecaptcha-badge {
    box-shadow: none !important;
}
.qr-scanner {
    width: 100%;
}
.loadingBox {
    display: flex;
    align-content: center;
    justify-content: center;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    background-color: rgb(255 255 255 / 50%);
    padding-top: 200px;
}
/* .sheet * {
    font-family: 'Times New Roman', Times, serif;
    font-size: 24pt !important;
    color: black !important;
} */
.gachduoi {
    border: 1px solid black;
    width: 30%;
    margin-left: 35%;
    margin-top: 10px;
}
.react-date-picker__wrapper {
    border: 0;
}
.react-date-picker__wrapper .react-date-picker__button {
    padding: 0;
}
.react-date-picker__wrapper .react-date-picker__inputGroup__input {
    background-color: transparent;
    outline: transparent;
}
/* @media print {
    * {
        font-size: 15pt !important;
    }
} */
.danhsach-congty .table-responsive>.table-bordered {
    border: 1px solid #dee2e6;
}
.first-uppercase::first-letter {
    text-transform: capitalize;
}
.rbt-menu.dropdown-menu {
    /* width: 100% !important;
    max-width: 900px; */
}