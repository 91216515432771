@page { margin: 0 }
body { margin: 0 }
.sheet {
  margin: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  page-break-after: always;
}

/** Paper sizes **/
body.A3           .sheet { width: 297mm; height: 419mm }
body.A3.landscape .sheet { width: 420mm; height: 296mm }
body.A4           .sheet { width: 210mm; height: 296mm }
body.A4.landscape .sheet { width: 297mm; height: 209mm }
body.A5           .sheet { width: 148mm; height: 209mm }
body.A5.landscape .sheet { width: 210mm; height: 147mm }

/** Padding area **/
.sheet.padding-10mm { padding: 10mm }
.sheet.padding-15mm { padding: 15mm }
.sheet.padding-20mm { padding: 20mm }
.sheet.padding-25mm { padding: 25mm }

/** For screen preview **/
@media screen {
  body { background: #e0e0e0 }
  .sheet {
    background: white;
    box-shadow: 0 .5mm 2mm rgba(0,0,0,.3);
    margin: 5mm;
  }
}

.pr-5rem {
  padding-right: 5rem !important;
}

/** Fix for Chrome issue #273306 **/
@media print {
           body.A3.landscape { width: 420mm }
  body.A3, body.A4.landscape { width: 297mm }
  body.A4, body.A5.landscape { width: 210mm }
  body.A5                    { width: 148mm }

  body *, .sheet * {
    font-family: 'Times New Roman', Times, serif;
    font-size: 20pt !important;
    color: black !important;
  }
  .sheet {
    margin-left: 10mm;
  }
  .header-page * {
    font-size: 17pt !important;
  }

  .ketqua-table, .ketqua-table td {
    border-color: black !important;
  }
  .sheet footer {
    text-align: center;
    position: fixed;
    bottom: 20px;
    right: 0;
    left: 0;
  }
  .khoang-dongdau {
    padding: 5rem !important;
  }
}
